@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black-gradient: linear-gradient(144.39deg,
      #ffffff -278.56%,
      #6d6d6d -78.47%,
      #11101d 91.61%);
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
}

.text-gradient {
  background: radial-gradient(64.18% 64.18% at 71.16% 35.69%,
      #def9fa 0.89%,
      #bef3f5 17.23%,
      #9dedf0 42.04%,
      #7de7eb 55.12%,
      #5ce1e6 71.54%,
      #33bbcf 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-blue-gradient {
  background: linear-gradient(157.81deg,
      #def9fa -43.27%,
      #bef3f5 -21.24%,
      #9dedf0 12.19%,
      #7de7eb 29.82%,
      #5ce1e6 51.94%,
      #33bbcf 90.29%);
}

.bg-black-gradient {
  background: linear-gradient(144.39deg,
      #ffffff -278.56%,
      #6d6d6d -78.47%,
      #11101d 91.61%);
}

.bg-black-gradient-2 {
  background: linear-gradient(-168.39deg,
      #ffffff -278.56%,
      #6d6d6d -78.47%,
      #11101d 91.61%);
}

.bg-gray-gradient {
  background: linear-gradient(153.47deg,
      rgba(255, 255, 255, 0) -341.94%,
      #14101d 95.11%);
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card {
  position: relative;
  cursor: pointer;
}

.feature-card>* {
  z-index: 10;
}

.feature-card:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
  transition: .3s all;
  opacity: 0;
}

.feature-card:hover:before {
  opacity: 1;
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}


.feedback-card {
  position: relative;
  cursor: pointer;
}

.feedback-card>* {
  z-index: 10;
}

.feedback-card:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
  transition: .3s all;
  opacity: 0;
}

.feedback-card:hover:before {
  opacity: 1;
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}

.pink__gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}

.card-layer {
  transform: matrix(0.87, 0.5, -0.87, 0.5, 0, 0);
  background: linear-gradient(158.59deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
  border-radius: 13.7985px;
  filter: drop-shadow(4px 22px 40px rgba(0, 0, 0, 0.25));
  border: 1px solid gray;
}

.card-layer__top-block {
  background: linear-gradient(92.79deg, #5AE5EA -110.42%, rgba(90, 229, 234, 0) -38.07%, #5AE5EA 134.95%);
}

.text-shadow {
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
}

.client-item{
  transition: .3s all;
  cursor: pointer;
}

.client-item:hover{
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(297deg) brightness(108%) contrast(102%);
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}